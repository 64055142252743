import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core"
import Lead from "../global/text/lead"

const HomeBreadcrumb = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "home/kaynaksquare.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Box mb={10} mt={3}>
      <Paper elevation={4} style={{ overflow: "hidden" }}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={6} xl={9}>
            <Box p={3} textAlign="center">
              <Typography
                color="textPrimary"
                variant="h4"
                component="h1"
                style={{ fontWeight: 500 }}
              >
                Paslanmaz Filtre ve Elek İmalatı
              </Typography>
              <Typography
                color="textSecondary"
                variant="subtitle1"
                gutterBottom
              >
                <Box fontStyle="italic">
                  Türkiyenin her yerine gönderim yapılmaktadır.
                </Box>
              </Typography>

              <Box py={2}>
                <Divider />
              </Box>
              <Lead>
                Numune ya da resim - proje çizimine göre müşterilerimizin
                istekleri doğrultusunda <span>paslanmaz filtre imalatı </span>
                gerçekleştirmekteyiz.{" "}
              </Lead>
              <Lead>
                {" "}
                <span>
                  Silindir Filtre, Huni Filtre, Emiş Filtresi, Seperatör Eleği,
                  Analiz Eleği vb.
                </span>{" "}
                bir çok sektörün filtre ihtiyaçlarını karşılamaktayız.
              </Lead>
              <Lead>
                Hızlı bilgi ve fiyat teklifi almak için iletişime
                geçebilirsiniz.
              </Lead>
              <Box pt={2} textAlign="center">
                <Link to="iletisim" style={{ textDecoration: "none" }}>
                  <Button variant="outlined" color="secondary">
                    İLETİŞİM BİLGİLERİ
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
            <div className="img-wrapper">
              <Img
                fluid={data.file.childImageSharp.fluid}
                loading="eager"
                fadeIn={false}
                alt="Erez Kaynak"
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}
export default HomeBreadcrumb
