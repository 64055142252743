import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core"

import Lead from "../global/text/lead"
const YoutubeTanitim = () => {
  const [videoVisible, showVideo] = React.useState(false)
  const openYouTubeVideo = () => {
    showVideo(!videoVisible)
  }
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "home/erez-youtube.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Box pb={5}>
      <Paper elevation={4}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={6} style={{ padding: "20px" }}>
            <Paper elevation={4}>
              {videoVisible ? (
                <div className="embed-responsive">
                  <iframe
                    title="erez kaynak tanıtım"
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/rZpN0Uyqp_o?autoplay=1&mute=1"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              ) : (
                <div onClick={openYouTubeVideo} role="button">
                  <Img
                    className="img-fluid cursor-pointer"
                    fluid={data.file.childImageSharp.fluid}
                    loading="eager"
                    fadeIn={false}
                    alt="Erez Kaynak Tanıtım"
                  />
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Box p={3} textAlign="center">
              <Typography
                color="textPrimary"
                variant="h4"
                component="h2"
                gutterBottom
              >
                <Box fontWeight={500}>Çelik Hasır ve Filtre Elek İmalatı</Box>
              </Typography>
              <Divider />
              <Lead>
                Müşterilerimizin istekleri doğrultusunda istenilen şekil, numune
                ya da projeye göre <span>filtre, elek</span> imalatı
                gerçekleştirmekteyiz.
              </Lead>
              <Lead>
                İstenilen tel kalınlığında ve ebatında, göz aralığı fark
                etmeksizin <span>punta kaynaklı hasır(çesan)</span> imalatı
                yapmaktayız. Stok ürün yada özel sipariş için iletişime geçiniz.
              </Lead>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}
export default YoutubeTanitim
